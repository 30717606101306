<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th colspan="6" class="th_style signos_vitales text-center">Registro de signos vitales</b-th>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Hora</b-th>
                            <b-th class="th_style signos_vitales text-center">Temp.</b-th>
                            <b-th class="th_style signos_vitales text-center">F.r.</b-th>
                            <b-th class="th_style signos_vitales text-center">F.c.</b-th>
                            <b-th class="th_style signos_vitales text-center">T.a.</b-th>
                            <b-th class="th_style signos_vitales text-center">SPO2</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Ingresos parentales</b-th>
                            <b-th class="th_style signos_vitales text-center">ML.</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style text-right">Total</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Egresos</b-th>
                            <b-th class="th_style signos_vitales text-center">ML.</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-th class="th_style">Sangrado</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr> 
                        <b-tr>
                            <b-th class="th_style">Diuresis</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Líquito abdominal</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Otros</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Drenajes</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style text-right">Total</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style text-right">Balance total</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th colspan="3" class="th_style signos_vitales text-center">Conteo de material e instrumental</b-th>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Cuenta</b-th>
                            <b-th class="th_style signos_vitales text-center">Inicio</b-th>
                            <b-th class="th_style signos_vitales text-center">Término</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-th class="th_style">Instrumental</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr> 
                        <b-tr>
                            <b-th class="th_style">Gasas con trama</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Compresas</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr> 
                        <b-tr>
                            <b-th class="th_style">Mat. punzocortante</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Otros</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Pieza patológica</b-th>
                            <b-td colspan="2" class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Medicamentos</b-th>
                            <b-th class="th_style signos_vitales text-center">Hora</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <p class="txt_secondary text-uppercase pt-3"><strong>Reporte de enfermería</strong></p>
                <b-form-textarea autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="5" max-rows="5" />
            </b-col>
        </b-row>
    </div>  
</template>

<script>
export default {

}
</script>